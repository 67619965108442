<template>
  <a-card :bordered="false" class="card-area">
    <!-- 搜索区域 -->
    <a-form layout="horizontal">
      <span class="fold">
        <a-row>
           <a-col :md="6" :sm="6">
            <a-form-item
                label="发布人"
                :labelCol="{span: 4}"
                :wrapperCol="{span: 12, offset: 1}">
              <a-input v-model.trim="queryParams.username"/>
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="6">
            <a-form-item
                label="关联公司"
                :labelCol="{span: 4}"
                :wrapperCol="{span: 12, offset: 1}">
              <a-input v-model.trim="queryParams.companyName"/>
            </a-form-item>
          </a-col>
        </a-row>
      </span>
      <span style="float: right; margin-top: 3px;">
          <a-button type="primary" @click="searchTable()" :loading="showLoading">查询</a-button>
          <a-button style="margin-left: 8px" @click="reset">重置</a-button>
      </span>
    </a-form>
    <a-table :dataSource="tableData" rowKey="id" size="small" :bordered="true" :columns="columns"
             :scroll="{ x: 1000 }">
      <template slot="imghead" slot-scope="text">
        <div v-if="text.uhead" class="img-list">
          <el-image style="width: 40px; height: 40px" :src="(text.uhead || '') | fullImgPath" fit="contain"
                    :preview-src-list="(text.uhead || '').split(',')"/>
        </div>
      </template>
      <template slot="status" slot-scope="text,record">
        <a-tag :color="record.status | dictName('inagencyNStatus', 'color')">
          {{ record.status | dictName("inagencyNStatus") }}
        </a-tag>
      </template>
      <template slot="operation" slot-scope="text, record">
        <a-button v-if="record.orderCount && record.orderCount > 0" type="primary" @click="linkOrder(record)">
          {{record.orderCount}} 条订单
        </a-button>
        <a-button v-if="record.orderCount == 0" type="default">没有订单</a-button>
      </template>
    </a-table>
  </a-card>
</template>
<script>
import {DICT} from "@/utils/dict";

export default {
  name: "inagencyList",
  data() {
    return {
      tableData: [],
      value: '',
      showLoading: false,
      queryParams: {
        companyName: '',
        username: ''
      }
    };
  },
  created() {
    this.searchTable();
  },
  computed: {
    inagencyNStatus() {
      return DICT['inagencyNStatus']
    },
    columns() {
      return [{
        title: "关联公司",
        width: 120,
        align: "center",
        dataIndex: "companyName",
      }, {
        title: "发布人",
        width: 80,
        align: "center",
        dataIndex: "username",
      }, {
        title: "情报费",
        width: 80,
        align: "center",
        dataIndex: "amg",
      }, {
        title: "股东价格",
        width: 80,
        align: "center",
        dataIndex: "yamg",
      }, {
        title: "有效期",
        width: 80,
        align: "center",
        dataIndex: "vdate",
      }, {
        title: "情报说明",
        width: 120,
        align: "center",
        dataIndex: "mark",
      }, {
        title: "创建日期",
        width: 80,
        align: "center",
        dataIndex: "createtime",
      }, {
        title: '操作',
        width: 160,
        align: 'center',
        dataIndex: 'operation',
        scopedSlots: {customRender: 'operation'}
      }];
    }
  },
  methods: {
    handleDateChange(value) {
      if (value) {
        this.queryParams.createTimeFrom = value[0]
        this.queryParams.createTimeTo = value[1]
      }
    },
    linkOrder(row) {
      this.$router.push({path: '/orders/orderList', query: {id: row.id}})
    },
    reset() {
      this.queryParams = {
        companyName: ''
      }
    },

    searchTable() {
      let that = this;
      that.showLoading = true;
      console.log(this.queryParams)
      that.$postJson("jobIntro/inagencyList", this.queryParams).then((res) => {
        that.showLoading = false;
        that.tableData = res.data;
      });
    }
  }
}
</script>
<style>
.el-row {
  margin-bottom: 20px;
}

.el-col {
  border-radius: 4px;
}

.bg-purple-dark {
  background: #99a9bf;
}

.bg-purple {
  background: #d3dce6;
}

.bg-purple-light {
  background: #e5e9f2;
}

.grid-content {
  border-radius: 4px;
  min-height: 36px;
}

.row-bg {
  padding: 10px 0;
  background-color: #f9fafc;
}
</style>
